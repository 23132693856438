import { createSelector } from 'reselect';

import { VALIDATORS } from 'utils/constants';

import { _baseState, _msgState, _payments } from './selectors';

const baseSelector = () => createSelector([_baseState], items => items);

const deviceParams = () =>
  createSelector([_baseState], items => items.deviceParams);

const getDictionary = store => _baseState(store).dictionary;
const getLocations = store => _baseState(store).locations;
const getPopularCities = store => _baseState(store).popularCities;

const getProvinces = store =>
  getLocations(store).reduce((acc, elem) => {
    const correctProvinceName =
      elem.name === 'Limburg' ? `${elem.name}_${elem.country}` : elem.name;

    acc[correctProvinceName.toLowerCase()] = {
      cities: elem.cities,
      count: elem.count,
      country: elem.country,
    };

    return acc;
  }, {});

const getIsSearchSuggestionsOpen = store =>
  _baseState(store).isSearchSuggestionsOpen;

const getIsMobileSearchOpen = store => _baseState(store).isMobileSearchOpen;

const getSelectedSettlement = store => _baseState(store).selectedSettlement;
const getUserGeolocation = store => _baseState(store).userGeolocation;

const getSelectedProvince = store => _baseState(store).selectedProvince;

const getUrlStructure = store => _baseState(store).urlStructure;

const getDictionaryErrors = store => _baseState(store).dictionaryErrors;

const headerRect = () =>
  createSelector([_baseState], items => items.headerRect);

const validators = () =>
  createSelector([_baseState], items => {
    const convertRegExp = (regExp, defaultRegExp) =>
      regExp ? new RegExp(regExp, 'i') : defaultRegExp;
    return {
      namePattern: convertRegExp(
        items.profile_configs?.showname?.valid_pattern,
        VALIDATORS.NAME_PATTERN,
      ),
      websitePattern: convertRegExp(
        items.account_configs.url?.valid_pattern,
        VALIDATORS.WEBSITE_PATTERN,
      ),
      emailPattern: convertRegExp(
        items.account_configs.email?.valid_pattern,
        VALIDATORS.EMAIL_PATTERN,
      ),
      passwordPattern: convertRegExp(
        items.account_configs.password?.valid_pattern,
        VALIDATORS.PASSWORD_VALIDATOR_PATTERN,
      ),
      phoneInstructionOtherPattern: convertRegExp(
        items.account_configs.phone_instruction_other?.valid_pattern,
        VALIDATORS.PHONE_INSTRUCTION_OTHER,
      ),
      phoneNumberPattern: convertRegExp(
        items.account_configs.phone_number?.valid_pattern,
        VALIDATORS.PHONE_NUMBER_PATTERN,
      ),
    };
  });
const getNotifications = key =>
  createSelector([_baseState], items => items.notifications[key]);

const getNotificationList = () =>
  createSelector([_baseState], items => items.notificationsList);

const gettingNotificationsStatus = () =>
  createSelector([_baseState], items => items.notifications.fetchingStatus);

const profileConfigs = () =>
  createSelector([_baseState], items => items.profile_configs);

const accountConfigs = () =>
  createSelector([_baseState], items => items.account_configs);

const getStickyMySXJBarPosition = () =>
  createSelector([_baseState], items => items.topPositionMySJBar);

const getMediaConfigs = () =>
  createSelector([_baseState], items => items.media_configs);

const getLanguage = store => _baseState(store).lang;

const notificationsByKey = key =>
  createSelector([_baseState], items => items.notifications[key]);

const unreadCounts = getterKey =>
  createSelector(
    [_msgState, _baseState, _payments],
    (msgItems, baseItems, payments) => {
      const countsObj = {
        msg: msgItems.unreadCount,
        cart: payments?.shoppingCartCount,
        notifications: baseItems.notifications.unread_count,
      };
      if (getterKey) return countsObj[getterKey];
      return countsObj;
    },
  );

const getGeographyConfigs = () =>
  createSelector([_baseState], items => items.geographyConfigs || {});

export {
  getDictionary,
  baseSelector,
  profileConfigs,
  getSelectedSettlement,
  accountConfigs,
  getLanguage,
  getMediaConfigs,
  validators,
  getNotificationList,
  getNotifications,
  gettingNotificationsStatus,
  unreadCounts,
  deviceParams,
  getIsMobileSearchOpen,
  headerRect,
  getUrlStructure,
  getLocations,
  getSelectedProvince,
  getStickyMySXJBarPosition,
  notificationsByKey,
  getGeographyConfigs,
  getDictionaryErrors,
  getIsSearchSuggestionsOpen,
  getUserGeolocation,
  getProvinces,
  getPopularCities,
};
