import Locations from 'dummyData/Locations';
import MyAds from 'dummyData/MyAds';

import axios from 'services/axiosEnv';

// Aggregator api's

const fetchDynamicAggregatorAds = async (key, page, limit) =>
  await axios.get(`aggregator/${key}?offset=${page}&limit=${limit}`);

const fetchPopularCategories = async () =>
  await axios.get('aggregator/popular-categories');

const fetchAvailableAds = async (page, limit) =>
  await axios.get(`aggregator/available-now?offset=${page}&limit=${limit}`);

const fetchHighlightedEvents = async () =>
  await axios.get('aggregator/highlighted-events');

const fetchHighlightedVacancies = async () =>
  await axios.get('aggregator/highlighted-vacancies');

const fetchMostViewed = async () => await axios.get('aggregator/most-viewed');

const fetchAdStatistics = async (id, status) =>
  await axios.get(`aggregator/ads-statistics/${id}?status=${status}`);

const fetchAdStatisticsBulk = async (ids, status) =>
  await axios.post(`aggregator/ads-statistics?status=${status}`, { ids });

const fetchAdPosition = async ad_id =>
  await axios.get(`aggregator/ads-position/${ad_id}`);

const fetchAdPromotions = async refferenceId =>
  await axios.get(`aggregator/promotions?reference_id=${refferenceId}`);

const fetchAdListing = async body =>
  await axios.post('aggregator/advertisements', body);

const fetchAvailableFilters = async body =>
  await axios.post('aggregator/available-filters', body);

const fetchSearchSuggestions = async ({ term, category_id, label_id }) => {
  const categoryQuery = category_id ? `&category_id=${category_id}` : '';
  const labelQuery = label_id ? `&label_id=${label_id}` : '';

  return await axios.get(
    `aggregator/search/suggestions?term=${term}${categoryQuery}${labelQuery}`,
  );
};

const fetchProvinces = async (categoryId, labelId) => {
  const categoryQuery = categoryId ? `&category_id=${categoryId}` : '';
  const labelQuery = labelId ? `&label_id=${labelId}` : '';

  return await axios.get(
    `aggregator/provinces?status=public${categoryQuery}${labelQuery}`,
  );
};

const fetchPopularCities = async (categoryId, labelId) => {
  const categoryQuery = categoryId ? `?category_id=${categoryId}` : '';
  const labelQuery = labelId ? `&label_id=${labelId}` : '';

  return await axios.get(
    `aggregator/popular-cities${categoryQuery}${labelQuery}`,
  );
};

const fetchLocations = () =>
  // Todo need to make server call for fetching current data
  new Promise(resolve => {
    resolve(Locations);
  });

const fetchPossibilities = async () =>
  await axios.post('aggregator/available-filters', {
    types: ['possibilities'],
  });

const fetchAdFilters = async data =>
  await axios.post('aggregator/filters', data);

const gatAdSelectedFilters = async hash =>
  await axios.get(`aggregator/filters/cache?hash=${hash}`);

const setAdSelectedFilters = async data =>
  await axios.put('aggregator/filters/cache', data);

const fetchNowAvailable = async () =>
  await axios.post('aggregator/available-filters', {
    types: ['now_available'],
  });

const fetchWithPicturesResultsCount = async () =>
  await axios.post('aggregator/available-filters', {
    types: ['pictures'],
  });

const fetchAppearance = async () =>
  await axios.post('aggregator/available-filters', {
    types: ['build', 'hair_color', 'cup_size', 'descent'],
  });

const fetchPrice = () =>
  // Todo need to make server call for fetching current data
  new Promise(resolve => {
    resolve({
      min: 0,
      max: 250,
    });
  });
const fetchAvailability = async () =>
  await axios.post('aggregator/available-filters', {
    types: ['availability'],
  });

const fetchSpeakLanguages = async () =>
  await axios.post('aggregator/available-filters', {
    types: ['languages'],
  });

const fetchMeetingPlaces = async () =>
  await axios.post('aggregator/available-filters', {
    types: ['meet_places'],
  });

const fetchMyAds = () =>
  // Todo need to make server call for fetching current data
  new Promise(resolve => {
    resolve(MyAds);
  });
const getOrderInCart = async id =>
  await axios.get(`commerce/carts/order?account_id=${id}`);

const checkProductsOverlapping = async id =>
  await axios.get(`commerce/carts/overlapping?account_id=${id}`);

const deleteOrder = async (id, productId) =>
  await axios.delete(`commerce/orders/${id}/products?product_id=${productId}`);

const addProductsToOrders = async ({ id, body }) =>
  await axios.put(`commerce/orders/${id}/products`, body);

const payWithCredits = async body =>
  await axios.post(`commerce/payment/credits`, body);

const payWithCreditsShoppingCard = async (id, body) =>
  await axios.post(`commerce/orders/${id}/payment/credits`, body);

const getProductsInventory = async () => await axios.get('inventory/products');

const getPaymentsConfig = async () => await axios.get('payment/configs');

const getPayments = async () => await axios.get('payment/methods/enabled');

const getPaymentsMethods = async () => await axios.get('payment/paynl/methods');

const getPaymentUrl = async data => await axios.post('payment/paynl/url', data);
const getNeosurfPaymentUrl = async data =>
  await axios.post('payment/neosurf/url', data);

const getPricesInventory = async id =>
  await axios.get(`inventory/categories/${id}/prices`);

const getCreditBundlesInventory = async () =>
  await axios.get(`inventory/categories/bundles/credits`);

// Base calls
const fetchGeographyConfigs = async () => await axios.get('/geography/configs');

const fetchMediaConfigs = async () => await axios.get('/media/configs');

const generateImage = async data => await axios.post('/media/images', data);

const generateImageByPurpose = async ({ profile_id, data, purpose }) =>
  await axios.post(`/profile/image/${profile_id}?purpose=${purpose}`, data);

const deleteImageById = async ({ profile_id, account_id, data }) =>
  await axios.delete(
    `/profile/images?profile_id=${profile_id}&account_id=${account_id}`,
    {
      data: { photo_ids: data },
    },
  );

const updateImage = async (imgId, data, replace) =>
  await axios.post(`/media/image/${imgId}?replace=${replace}`, data);

const getImage = async imgId => await axios.get(`/media/image/${imgId}`);

const getNotificationsUnreadCount = async id =>
  await axios.get(`/account-notifications/unread?account_id=${id}`);

const getNotificationList = async ({ accountId, is_read, page, limit }) =>
  await axios.get(
    `/account-notifications/${accountId}?offset=${page}&limit=${limit}${
      is_read ? `is_read=${is_read}` : ''
    }`,
  );

const setNotificationAsRead = async ({ _id, account_id }) =>
  await axios.put(`/account-notifications/${account_id}/${_id}`);

const deleteNotification = async ({ _id, account_id }) =>
  await axios.delete(`/account-notifications/${account_id}/${_id}`);

const getNotifications = async ({ account_id, limit, page }) =>
  await axios.get(
    `/account-notifications/${account_id}?offset=${page}&limit=${limit}`,
  );

// Get translations

const getDictionary = async locale =>
  await axios.get(`/dictionary?locale=${locale}`);

const getDictionaryErrors = async () => await axios.get('/dictionary/error');

// Planner calls

const getCommerceConfig = async () => await axios.get('/commerce/config');

const getPlanners = async (accountId, refferenceId) =>
  await axios.get(
    `commerce/planner/schedules?account_id=${accountId}&reference_ids=${refferenceId}`,
  );

const createPlanner = async data =>
  await axios.post('/commerce/planner/schedules', data);

// Profile verification's

const getMinVerification = async ({ profileId, accountId, minVerification }) =>
  await axios.get(
    `/profile-verification/profiles/${profileId}/type?account_id=${accountId}&min_verification=${minVerification}`,
  );

const createIdVerification = async data =>
  await axios.post('/profile-verification/id', data);

const getVerifications = async data =>
  await axios.get('/profile-verification', data);

const createPersonVerification = async data =>
  await axios.post('/profile-verification/person', data);

const checkProfileVerificationExists = async id =>
  await axios.get(`/profile-verification/check/${id}`);

// Ad's calls
const createAd = async ({ id, data }) =>
  await axios.post(`/profile-ad/${id}`, data);

const resetAd = async id => await axios.get(`/profile-ad/${id}/reset`);

const updateAd = async ({ id, data }) =>
  await axios.patch(`/profile-ad/${id}`, data);

const removeActiveProduct = async body =>
  await axios.post(`/commerce/ad/product/cancel`, body);

const deleteAd = async ad_id => await axios.delete(`profile-ad/${ad_id}`);

const putOfflineAd = async ad_id => await axios.get(`profile-ad/${ad_id}`);

const getAd = async ({ id, status }) =>
  await axios.get(`/profile-ad/${id}?status=${status}`);

const getAdVerificationStatus = async ({ profileId, accountId, ad_id }) =>
  await axios.get(
    `/profile-verification/status/${profileId}?account_id=${accountId}&ad_id=${ad_id}`,
  );

const getAdsCount = async () => await axios.get('/profile-ad/counts');

// const getProfileCategories = async ({ id }) => await axios.get(`/profile-ad/categories/${id}`);

const getCategory = async id => await axios.get(`inventory/categories/${id}`);

const getProfileCategories = async () =>
  await axios.get('inventory/categories');

const getExistingAdCountInCategory = async (category_id, profile_id) =>
  await axios.get(
    `profile-ad/group/categories/count?category_id=${category_id}&profile_id=${profile_id}`,
  );

const getProfileCategoriesWithFilter = async body =>
  await axios.post('inventory/categories/filter', body);

const getLabelsOfCategory = async body =>
  await axios.post(`inventory/categories/sections/filter`, body);

const getCategoryServices = async id =>
  await axios.get(`inventory/categories/${id}/services`);

const getAllCategoryServices = async () =>
  await axios.get(`/inventory/categories/services`);

// const fetchAccountAds = async () => await axios.get('/profile-ad/account');

const getProfileAds = async ({ id, offset, limit, purpose }) =>
  await axios.get(
    `profile-ad/account/${id}?offset=${offset}&limit=${limit}&purpose=${purpose}`,
  );

const updateProfileAd = async ({ id, body }) =>
  await axios.patch(`profile-ad/${id}`, body);

const getProfileFilteredAds = async ({ id, offset, limit, status, body }) =>
  await axios.post(
    `profile-ad/filter?offset=${offset}&limit=${limit}&status=${status}&account_id=${id}`,
    body,
  );

const getAds = async id => await axios.get(`profile-ad/${id}`);

const getAdDetails = async ({ id }) => await axios.get(`/profile-ad/${id}`);

const updateAdDetails = async ({ id, body }) =>
  await axios.get(`/profile-ad/${id}`, body);

const putAdOffline = async body =>
  await axios.post(`/profile-ad/offline`, body);

const putAdOnline = async body => await axios.post(`/profile-ad/online`, body);

const saveAdAsDraft = async ({ data }) =>
  await axios.post('/profile-ad/drafts', data);

const fetchAdDraft = async ({ id }) =>
  await axios.get(`/profile-ad/drafts/${id}`);

const fetchAdConfigs = async () => await axios.get('/profile-ad/configs');

const fetchCategoriesWithSections = async () =>
  await axios.get('/inventory/categories/sections');

const getAddressByAutocomplete = async data =>
  await axios.post('/geography/autocomplete', data);

const getAddressBySuggestion = async data =>
  await axios.post('/geography/locations', data);

const getActiveProducts = async (data, status) =>
  await axios.post(`/commerce/ad/products?status=${status}`, data);

const getActiveProductsBulk = async (data, status) =>
  await axios.post(`/commerce/ad/products/bulk?status=${status}`, data);

const getAddressByAddress = async data =>
  await axios.post('/geography/address', data);

const fetchProfileAds = async ({ id }) =>
  await axios.get(`/profile-ad/profile/${id}`);

const getCreditsCount = async id =>
  await axios.get(`/commerce/credits/count?account_id=${id}`);

const getTransfersHistory = async data =>
  await axios.post('/commerce/transfers', data);

const getCreditsHistory = async data =>
  await axios.post('/commerce/transfers/credits', data);

const getOrder = async accountId =>
  await axios.get(`/commerce/carts/order?account_id=${accountId}`);

const getProductsCountInCart = async id =>
  await axios.get(`/commerce/carts/products/count?account_id=${id}`);

const saveProducts = async ({ id, body }) =>
  await axios.put(`/commerce/orders/${id}/products`, body);

const getAdsImages = async () => await axios.get('/profile-ad/image');

const getAdsImagesByAdIds = async ({ ids }) =>
  await axios.post('/profile/images/profile-ads', { ids });

const setMainPhoto = async ({ profile_id, data }) =>
  await axios.post(`/profile/image/main/${profile_id}`, data);

const getAdsImagesByProfileId = async ({
  id,
  purpose,
  ad_id,
  status = 'private',
}) =>
  await axios.get(
    `/profile/images?${
      id ? `profile_id=${id}` : `ad_id=${ad_id}`
    }&purpose=${purpose}&status=${status}`,
  );

const getAdsPublicImagesByProfileId = async ({ ad_id }) =>
  await axios.get(
    `/profile/images/profile-ads/${ad_id}/approved-photos?status=public`,
  );

const getImagesBulk = async (data, status) =>
  await axios.post(`media/images/bulk?status=${status}`, data);

// Auth calls

const emailVerification = async ({ account_id, verification_code }) =>
  await axios.get(
    `/contact-verification/email/${account_id}/${verification_code}`,
  );

const phoneVerification = async body =>
  await axios.post(`/contact-verification/send-phone-number`, body);

const submitPhoneVerification = async body =>
  await axios.post(`/contact-verification/verify-phone-number`, body);

const resetPassword = async ({ account_id, data }) =>
  await axios.post(`/account/${account_id}/password/reset`, data);

const sendEmailRecover = async email =>
  await axios.post('/account/passwords/recover', { email });

const changeLanguage = async ({ lang }) =>
  await axios.put('/account/languages', {
    preferences: { language: lang },
  });

const updateCompanyProfile = async ({ profile_id, formData }) =>
  await axios.put(`/profile/company/${profile_id}`, {
    data: { ...formData },
  });

const updateLastActivityDate = async ({ accountId }) =>
  await axios.put(`account/${accountId}/activity`);

const getProfile = async ({ id, status }) =>
  await axios.get(`/profile/temp/${id}?status=${status}`);

const updateProfile = async ({ profile_id, formData }) =>
  await axios.put(`/profile/temp/${profile_id}`, {
    data: { ...formData },
  });

const deleteProfile = async ({ profile_id }) =>
  await axios.delete(`/profile/temp/${profile_id}`);

const deleteAccount = async () => await axios.delete('/account');

const createProfile = async ({ data }) => await axios.post('/profile', data);

const createAccount = async data => await axios.post('/account?create=1', data);

const getAccount = async ({ id }) => await axios.get(`/account/${id}`);

const updateBillingInfo = async ({ id, body }) =>
  await axios.put(`account/${id}/billing-info`, body);

const createCompanyProfile = async ({ data }) =>
  await axios.post('/profile/company', {
    ...data,
  });

const fetchNotificationSettings = async ({ account_id }) =>
  await axios.get(`/account-notifications/settings/${account_id}`);

const updateNotificationSettings = async ({ account_id, data }) =>
  await axios.patch(`/account-notifications/settings/${account_id}`, data);

const fetchAccountConfigs = async () => await axios.get('/account/configs');

const fetchProfileConfigs = async () => await axios.get('/profile/configs');

const changeEmail = async ({ account_id, data }) =>
  await axios.post(`/account/${account_id}/email/change`, data);

// const changeEmail = async ({ account_id, data }) => await axios.post(`/account/${account_id}`, data);

const forgotPassword = async ({ email }) =>
  await axios.post('/account/passwords/recover', { email });

const changePassword = async ({ account_id, data }) =>
  await axios.post(`/account/${account_id}/password/change`, data);

const getAdDetailsProfiles = async ({ ids, status }) =>
  await axios.post(`/profile/bulk?status=${status}`, {
    ids,
  });

const getAccountsState = async ({ ids }) =>
  await axios.post('/account/bulk', { ids });

const addWebSite = async ({ account_id, data }) =>
  await axios.post(`/account/${account_id}/websites`, data);

const deleteWebSite = async ({ account_id, url }) =>
  await axios.delete(`/account/${account_id}/websites?url=${url}`);

const addPhone = async ({ account_id, data }) =>
  await axios.post(`/account/${account_id}/phone-numbers`, data);

const deletePhone = async ({ account_id, phone_number, phone_country_code }) =>
  await axios.delete(
    `/account/${account_id}/phone-numbers?phone_country_code=${phone_country_code}&phone_number=${phone_number}`,
  );

// Messenger calls
const fetchMsgSettings = async ({ accountId }) =>
  await axios.get(`/messenger/settings?account_id=${accountId}`);

const fetchMessengerConfigs = async () => await axios.get('/messenger/configs');

const updateMsgSettings = async ({ newSettings }) =>
  await axios.patch('/messenger/settings', newSettings);

const fetchProfiles = async ({ accountId, url }) =>
  await axios.get(`/${url}?account_id=${accountId}`);

const getThreads = async data => await axios.post('/messenger/threads', data);

const searchMyThreads = async ({ search, _page, _limit }) =>
  await axios.post(
    `/messenger/threads/_search?_page=${_page}&_limit=${_limit}`,
    {
      term: search,
    },
  );

const fetchFilters = async () => await axios.get('/messenger/threads/_filter');

const updateFilters = async ({ data, offset, limit }) =>
  await axios.post(
    `/messenger/threads/_filter?offset=${offset}&limit=${limit}`,
    { filters: data },
  );

const fetchThreadMessages = async ({ id, offset, limit, account_id }) =>
  await axios.get(
    `/messenger/message/${id}?offset=${offset}&limit=${limit}&account_id=${account_id}`,
  );

const setThreadAsRead = async ({ id, account_id }) =>
  await axios.patch(`/messenger/threads/${id}?account_id=${account_id}`);

const getUnreadCount = async ({ accountId }) =>
  await axios.get(`/messenger/message/unread?account_id=${accountId}`);

const deleteConversation = async ({ thread_id, account_id }) =>
  await axios.delete(
    `/messenger/threads/${thread_id}?account_id=${account_id}`,
  );

const undoConversation = async ({
  type,
  thread_id,
  account_id,
  deleted_until,
}) =>
  await axios.patch(
    `/messenger/undo/${thread_id}?account_id=${account_id}&type=${type}&deleted_until=${deleted_until}`,
  );

const reportConversation = async ({
  type,
  thread_id,
  account_id,
  reporter_id,
}) =>
  await axios.post(`/messenger/reports/?account_id=${account_id}`, {
    reporter_id,
    thread_id,
    type,
  });

const unBlockThread = async ({ threadId, account_id }) =>
  await axios.patch(
    `/messenger/threads/unblock/${threadId}?account_id=${account_id}`,
  );

const blockThread = async ({ threadId, account_id }) =>
  await axios.patch(
    `/messenger/threads/block/${threadId}?account_id=${account_id}`,
  );

const markAsReadAllThread = async account_id =>
  await axios.patch(`/messenger/threads?account_id=${account_id}`);

const createMessage = async data =>
  await axios.post('/messenger/message', {
    ...data,
  });

const sendAdVisitor = async data =>
  await axios.post('/statistic/advertisement/add-view', data);

const getInvoicePdf = async ({ id, accountId, env }) =>
  await axios.get(
    `/file-generator/invoices/pdf?order_id=${id}&account_id=${accountId}&initializing_environment=${env}`,
  );

const acceptCookies = async account_id =>
  await axios.post(`account/${account_id}/cookies/accept`);

const acceptRecover = async account_id =>
  await axios.post(`account/${account_id}/recover/accept`);

const getAuthTokens = async data =>
  await axios.post('account/auth/tokens', data);

const getAuthRefreshToken = async token =>
  await axios.post('account/auth/refresh', undefined, {
    ...(token && {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  });

const validateUrl = async data => await axios.post('/cms/url/validate', data);

const sendFrontError = async data => await axios.post('/dictionary/log', data);

const updateAddressVisibility = async ({ profileId, data }) =>
  await axios.patch(`/profile/address-visibility/${profileId}`, data);

export default {
  getAd,
  getAds,
  resetAd,
  getImage,
  getOrder,
  createAd,
  updateAd,
  addPhone,
  deleteAd,
  fetchPrice,
  fetchMyAds,
  getAccount,
  getThreads,
  addWebSite,
  getProfile,
  updateImage,
  validateUrl,
  getPlanners,
  getAdsCount,
  getCategory,
  getPayments,
  changeEmail,
  deleteOrder,
  deletePhone,
  putAdOnline,
  blockThread,
  setMainPhoto,
  putAdOffline,
  getAdsImages,
  saveProducts,
  fetchAdDraft,
  fetchFilters,
  putOfflineAd,
  getAdDetails,
  acceptCookies,
  getAuthTokens,
  changeLanguage,
  acceptRecover,
  generateImage,
  getProfileAds,
  createPlanner,
  saveAdAsDraft,
  resetPassword,
  updateProfile,
  deleteProfile,
  deleteAccount,
  createProfile,
  createAccount,
  fetchProfiles,
  deleteWebSite,
  updateFilters,
  unBlockThread,
  getPaymentUrl,
  getDictionary,
  createMessage,
  getImagesBulk,
  sendAdVisitor,
  getInvoicePdf,
  fetchLocations,
  getOrderInCart,
  fetchAdConfigs,
  fetchAdListing,
  payWithCredits,
  changePassword,
  forgotPassword,
  fetchAdFilters,
  fetchProvinces,
  getUnreadCount,
  sendFrontError,
  fetchAppearance,
  updateAdDetails,
  updateProfileAd,
  getCreditsCount,
  fetchProfileAds,
  fetchMostViewed,
  searchMyThreads,
  fetchAdPosition,
  setThreadAsRead,
  deleteImageById,
  undoConversation,
  getNotifications,
  getCommerceConfig,
  sendEmailRecover,
  fetchMsgSettings,
  getAccountsState,
  getVerifications,
  fetchAdPromotions,
  fetchMediaConfigs,
  fetchAvailableAds,
  updateBillingInfo,
  fetchAvailability,
  getActiveProducts,
  emailVerification,
  phoneVerification,
  getCreditsHistory,
  fetchAdStatistics,
  getPaymentsConfig,
  updateMsgSettings,
  fetchNowAvailable,
  deleteNotification,
  fetchPossibilities,
  getDictionaryErrors,
  fetchMeetingPlaces,
  getPricesInventory,
  getPaymentsMethods,
  deleteConversation,
  reportConversation,
  fetchPopularCities,
  getMinVerification,
  getNotificationList,
  fetchSpeakLanguages,
  removeActiveProduct,
  addProductsToOrders,
  getTransfersHistory,
  getAddressByAddress,
  fetchAccountConfigs,
  fetchProfileConfigs,
  getLabelsOfCategory,
  getCategoryServices,
  fetchThreadMessages,
  markAsReadAllThread,
  getAdsImagesByAdIds,
  getAuthRefreshToken,
  createIdVerification,
  getProductsInventory,
  updateCompanyProfile,
  setAdSelectedFilters,
  gatAdSelectedFilters,
  createCompanyProfile,
  fetchAdStatisticsBulk,
  getActiveProductsBulk,
  getAdDetailsProfiles,
  getProfileCategories,
  getNeosurfPaymentUrl,
  setNotificationAsRead,
  getProfileFilteredAds,
  fetchGeographyConfigs,
  fetchMessengerConfigs,
  fetchAvailableFilters,
  generateImageByPurpose,
  getProductsCountInCart,
  updateLastActivityDate,
  fetchHighlightedEvents,
  fetchPopularCategories,
  fetchSearchSuggestions,
  getAddressBySuggestion,
  getAllCategoryServices,
  submitPhoneVerification,
  updateAddressVisibility,
  getAdsImagesByProfileId,
  getAdVerificationStatus,
  createPersonVerification,
  getAddressByAutocomplete,
  checkProductsOverlapping,
  getCreditBundlesInventory,
  fetchDynamicAggregatorAds,
  fetchNotificationSettings,
  // fetchThreadUserDetails,
  fetchHighlightedVacancies,
  updateNotificationSettings,
  payWithCreditsShoppingCard,
  getNotificationsUnreadCount,
  fetchCategoriesWithSections,
  getExistingAdCountInCategory,
  fetchWithPicturesResultsCount,
  getAdsPublicImagesByProfileId,
  getProfileCategoriesWithFilter,
  checkProfileVerificationExists,
};
