import { useState, useEffect, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';
import useDebounce from 'hooks/use-debounce';
import useMemoSelector from 'hooks/useMemoSelector';

import AsideMenu from 'Components/AsideMenu';

import { convertCapitalize } from 'utils/handlers';

import {
  deviceParams,
  getIsMobileSearchOpen,
  getSelectedSettlement,
} from 'store/reselect';
import { setSearchSuggestionsOpen, setSelectedProvince } from 'store/actions';

import API from 'services/api';

import { useStyles } from './styles';
import SearchWithSuggestions from './searchWithSuggestions';

const Search = ({ headerHeight }) => {
  const t = useTranslations();
  const dispatch = useDispatch();

  const router = useRouter();
  const styles = useStyles();
  const { deviceType, selectedSettlement, isMobileSearchOpen } =
    useMemoSelector(store => ({
      deviceType: deviceParams()(store).deviceType,
      selectedSettlement: getSelectedSettlement(store),
      isMobileSearchOpen: getIsMobileSearchOpen(store),
    }));

  const [searchValue, setSearchValue] = useState();
  const [suggestions, setSuggestions] = useState();

  const [suggestionsPayload, setSuggestionsPayload] = useState({});
  const [hasDistancesOption, setHasDistancesOption] = useState(false);

  const debouncedValue = useDebounce(searchValue, 500);

  useEffect(() => {
    (async () => {
      try {
        if (!router.query.slug?.length) {
          setSearchValue('');
          return;
        }

        const { category_id, selectedFilters, ...rest } = router.query;

        const { data } = await API.validateUrl({
          ...rest,
        });

        const {
          isNearby,
          selectedCity,
          label_ids = [],
          selectedProvince,
          category_id: categoryId,
        } = data || {};

        setSuggestionsPayload({
          category_id: categoryId,
          label_id: label_ids[0],
        });

        const withDistance = !!selectedCity || !!selectedProvince || !!isNearby;

        setHasDistancesOption(withDistance);

        const searchedValueFromUrl = selectedCity || selectedProvince;

        setSearchValue(
          convertCapitalize(searchedValueFromUrl) ||
            router.query.term ||
            t(router.query.type),
        );
      } catch (error) {
        console.error(error);
      }
    })();
  }, [router.query, router.query.slug, router.query.type, t]);

  useEffect(() => {
    setSearchValue(selectedSettlement);
  }, [selectedSettlement]);

  useEffect(() => {
    if (!debouncedValue || !searchValue) return;

    const getSuggest = async () => {
      try {
        const { data } = await API.fetchSearchSuggestions({
          term: debouncedValue,
          ...suggestionsPayload,
        });

        setSuggestions(data);
      } catch (error) {
        setSuggestions([]);
        console.error(error);
      }
    };

    getSuggest();
  }, [debouncedValue, searchValue, suggestionsPayload]);

  useEffect(() => {
    if (!searchValue) {
      setSuggestions([]);
    }
  }, [searchValue]);

  useLayoutEffect(() => {
    if (isMobileSearchOpen) {
      document.body.classList.add('body--fixed__overflow--no-scroll');
    } else {
      document.body.classList.remove('body--fixed__overflow--no-scroll');
    }
  }, [isMobileSearchOpen]);

  const isMobile = deviceType === 'mobile';

  return (
    <div
      id="search-input-wrapper"
      className={clsx(styles['search-wrapper'], 'opened_mobile')}
    >
      {isMobileSearchOpen ? (
        <AsideMenu>
          <div className={styles['search-mobile']} id="search-aside-wrapper">
            <SearchWithSuggestions
              autoFocus
              isMobile={isMobile}
              searchValue={searchValue}
              suggestions={suggestions}
              headerHeight={headerHeight}
              setSearchValue={setSearchValue}
              setSuggestions={setSuggestions}
              hasDistancesOption={hasDistancesOption}
              isMobileSearchOpen={isMobileSearchOpen}
              onFocus={() => dispatch(setSearchSuggestionsOpen(true))}
              onClickOutside={() => {
                dispatch(setSelectedProvince(''));
                dispatch(setSearchSuggestionsOpen(false));
              }}
            />
          </div>
        </AsideMenu>
      ) : (
        <SearchWithSuggestions
          isMobile={isMobile}
          searchValue={searchValue}
          suggestions={suggestions}
          headerHeight={headerHeight}
          setSearchValue={setSearchValue}
          setSuggestions={setSuggestions}
          hasDistancesOption={hasDistancesOption}
          isMobileSearchOpen={isMobileSearchOpen}
          onFocus={() => dispatch(setSearchSuggestionsOpen(true))}
          onClickOutside={() => {
            dispatch(setSelectedProvince(''));
            dispatch(setSearchSuggestionsOpen(false));
          }}
        />
      )}
    </div>
  );
};

export default Search;
