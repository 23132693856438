import { memo } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import useMemoSelector from 'hooks/useMemoSelector';

import { UiGenerateMargin } from 'utils/handlers';

import { getSelectedCategory, getUrlStructure } from 'store/reselect';

import useStyles from './styles';

const Tag = ({
  size,
  text,
  count,
  label,
  width,
  withX,
  toggle,
  margin,
  labelId,
  disabled,
  selected,
  withLock,
  isMultiSelect,
}) => {
  const router = useRouter();

  const { selectedCategory, urlStructure } = useMemoSelector(store => ({
    urlStructure: getUrlStructure(store),
    selectedCategory: getSelectedCategory(store, router.query.slug),
  }));

  const { selectedCity, selectedProvince } = urlStructure;

  const styles = useStyles();

  const toggleTag = tagName => {
    if (withX && tagName === 'div' && selected) return;
    toggle(label);
  };

  const isRightActions = withX || count || withLock;

  const inlineStylesParent = {
    ...UiGenerateMargin(margin),
    width: width ? `${width}px` : 'auto',
  };

  const categorySlug = selectedCategory?.value_nl;

  const { slug } =
    selectedCategory?.labels?.find(({ id }) => id === labelId) || {};

  const cityPath = selectedCity
    ? `${selectedCity.toLowerCase().replace(' ', '-')}`
    : '';
  const categoryPath = `${categorySlug}/`;
  const labelPath = `${slug}/` || '';
  const provincePath = `provincie-${selectedProvince}`;

  const path = `${categoryPath}${labelPath}${cityPath}${provincePath}`;

  const moveTo = () => {
    const { page, slug: _, ...query } = router.query;
    router.push({
      pathname: path,
      query,
    });
  };

  return (
    <div
      data-disabled={disabled}
      className={`${`${size}-wrapper-tag`} ${styles['tag-wrapper']} ${
        isRightActions ? 'with-actions' : ''
      } ${selected ? 'selected-tag' : ''}`}
      style={inlineStylesParent}
      onClick={() => {
        if (isMultiSelect) {
          return toggleTag('div');
        }

        moveTo();
      }}
    >
      {typeof window === 'undefined' ? (
        <Link href={path}>
          <a>
            <span className="tag-content">{text}</span>
          </a>
        </Link>
      ) : (
        <span className="tag-content">{text}</span>
      )}
      {isRightActions && (
        <div>
          {count && <span className="count-ads">({count})</span>}
          {withX && selected && (
            <span
              className="with-x icon-Close"
              onClick={e => {
                e.stopPropagation();
                if (isMultiSelect) {
                  return toggleTag('icon');
                }
                const {
                  query: { subCategory, slug: _, ...rest },
                } = router;
                router.push({
                  pathname: `${categoryPath}${cityPath}${provincePath}`,
                  query: { ...rest },
                });
              }}
            />
          )}
          {disabled && selected && (
            <span
              className="with-x icon-Lock"
              onClick={() => {
                if (isMultiSelect) {
                  return toggleTag('icon');
                }

                router.push({
                  pathname: `/${categorySlug}/${label}`,
                });
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

Tag.defaultProps = {
  margin: 0,
  size: 'md',
};

export default memo(Tag);
